import { ToastContainer } from "react-toastify";
import "./App.css";
import SpeedTest from "./components/SpeedTestWeb";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <div style={{ height: "0.9375rem" }} />
      <SpeedTest />
    </div>
  );
}

export default App;
