const ToggleSwitch = ({
  label,
  onClick,
  isSelected,
}: {
  label: string;
  onClick: () => void;
  isSelected: boolean;
}) => {
  const toggleStyle = {
    transform: "translateX(15px)",
    transition: "background-color 0.3s",
    backgroundColor: "rgb(21, 20, 20)",
  };

  const containerStyle = {
    width: "35px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    padding: "1px",
    cursor: "pointer",
    border: "1px solid #cecfc5",
    ...(isSelected
      ? { backgroundColor: "#f4fef6", borderColor: "#3d874e" }
      : { backgroundColor: "#f8f8f7" }),
  };

  const innerCircleStyle = {
    backgroundColor: "#cecfc5",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    transform: "translateX(0px)",
    transition: "background-color 0.3s",
    ...(isSelected ? toggleStyle : null),
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "4px",
        cursor: "pointer",
      }}
    >
      <div style={containerStyle} onClick={() => onClick()}>
        <div style={innerCircleStyle}></div>
      </div>
      <p style={{ paddingLeft: "5px" }}>{label}</p>
    </div>
  );
};

export default ToggleSwitch;
